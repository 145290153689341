<template>
  <div class="project-elem">
    <!-- View for Certificate -->
    <div class="project-view radius box-shadow contentbox" v-if="!editing">
      <div class="image-cont line">
        <img v-if="projectData.picture" :src="projectData.picture" />
      </div>

      <div class="label line">{{this.project.name}}</div>

      <div class="date-expl line" v-if="this.project.startDate">
        from: {{this.project.startDate | moment('MM/YY')}} -
        to: {{this.project.endDate| moment('MM/YY')}}
      </div>

      <div class="line" v-html="projectData.description">
      </div>

      <div class="line" v-if="projectData.url">
        <a :href="projectData.url" target="_blank">{{projectData.url}}</a>
      </div>

      <div class="global-param" v-if="editable">
        <div class="param ghost"></div>
        <div class="param" >
          <img src="@/assets/pictos/picto_param_purple.svg">
          <img class="hover" src="@/assets/pictos/picto_param_purple_full.svg">
        </div>
        <div class="cont-param" v-if="editable">
          <div class="elem-param" @click="toggleEdit">Edit proj.</div>
          <div class="elem-param delete" @click="deleteProject">Remove proj.</div>
        </div>
      </div>
    </div>
    <div class="project-edit radius box-shadow contentbox" v-if="editing">
      {{errorStatus? errorStatus: ''}}
      <div class="cont-form open">
        <div class="line">
          <label>Project name:  </label>
          <input  class="v-select" v-model="projectData.name" placeholder="Project Name"
            requiredVal="projectData.name" />
        </div>

        <div class="line">
          <label>Date start:  </label>

          <datepicker
            class="elem-date"
            :minimum-view="'month'"
            :format="'MMM yyyy'"
            v-model="projectData.startDate"
          />

          <!-- <div @click="() => {projectData.startDate = null}">Clear </div> -->
        </div>
        <div class="line">
          <label> End date:  </label>
          <datepicker
            class="elem-date"
            :minimum-view="'month'"
            :format="'MMM yyyy'"
            v-model="projectData.endDate"
          />
          <!-- <div @click="() => {projectData.endDate = null}">Clear </div> -->
        </div>

        <div class="line">
          <wysiwyg class="radius" v-model="projectData.description" />
        </div>

        <div class="line">
          <label> URL: </label>
          <input class="v-select" v-model="projectData.url" />
        </div>

        <div class="image-cont">
          <img v-if="projectData.picture" :src="projectData.picture" />
          <div class="btn-green radius full reverse" @click="attachPicture">
            {{projectData.picture? 'Edit Picture': 'Add Picture'}}</div>
        </div>

        <div class="line cont-btn" v-if="!submitting">
          <div class="btn-green small reverse radius" @click="cancel">Cancel</div>
          <div class="btn-green small radius" @click="submit">Submit</div>
        </div>

        <div class="line" v-else>
          sending ...
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// name organization issueDate expirationDate url
import Datepicker from 'vuejs-datepicker';
import validateMixin from '@/modules/mixins/validate';
import attachImage from '@/modules/mixins/selectMedia';

export default {
  name: 'Project',
  props: ['project', 'editable'],
  mixins: [validateMixin, attachImage],
  components: {
    Datepicker,
  },
  data() {
    const hasId = !this.project._id;
    return {
      editing: hasId && this.editable,
      new: hasId,
      projectData: { ...this.project },
      submitting: false,
      errorStatus: false,
    };
  },
  methods: {
    toggleEdit() {
      if (this.editable) {
        this.editing = !this.editing;
        this.sending = false;
      }
    },
    deleteProject() {
      this.$store.dispatch('company/deleteProject', this.project._id);
    },
    attachPicture() {
      this.openAttach('Image',
        (media) => { this.$set(this.projectData, 'picture', media.data); },
        true);
    },

    cancel() {
      this.editing = false;
      this.projectData = { ...this.project };
      this.$emit('editClosed');
    },

    submit() {
      this.errorStatus = false;

      if (this.validateFields()) {
        if (this.projectData.url && !this.validateUrl(this.projectData.url)) {
          this.errorStatus = 'Please enter a valid URL';
        } else {
          this.sending = true;
          if (this.new) {
            this.$store.dispatch('company/addProject', this.projectData).then(() => {
              this.editing = false;
              this.$emit('editClosed');
              this.sending = false;
            });
          } else {
            this.$store.dispatch('company/editProject', this.projectData).then(() => {
              this.editing = false;
              this.sending = false;
            });
          }
        }
      }
    },
  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .cont-btn{
    margin-top: 1vh !important;
  }
  .project-view{
    position: relative;
    width: calc(100% - 2vh);
    padding: 1vh;

    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    background-color: #ffffff;
    margin-bottom: 10px;
    &.radius {
      overflow: visible;
    }
    .label {
      font-size: $fnt-18;
      font-weight: 600;
    }
    .link{
      a{
        color: rgb(58, 2, 190)
      }
    }
    .line{
      margin-bottom: 1vh;
      &:last-child{
        margin: 0;
      }
    }
  }

  .project-edit{
    width: 100%;
    margin-bottom: 1vh;
    .line{
      margin-bottom: 1vh;
      &:last-child{
        margin: 0;
      }
    }

  }
</style>
