<template>
  <div
    class="elem-job whead box-shadow radius"
    @click.exact="redirect"
    @contextmenu.prevent="rightClick"
    @auxclick.prevent="rightClick"
  >
    <div class="head-job">
      <div class="logo" :style="`background-image:url(${companyInfo.companyPicture});`">
        <!-- <img :src="companyInfo.companyPicture" /> -->
      </div>
      <div class="info-job">
        <div class="title-job" v-if="elem.name">
          {{elem.companyName}}
        </div>
        <div class="offer-company" v-if="elem.companyName">
          {{elem.name}}
        </div>
      </div>
      <slot></slot>
    </div>
    <div class="content-job">
      <!-- <div class="desc-job" v-html="elem.description"></div> -->
      <!-- {{elem}} -->
      <div class="sub-job">
        <div class="elem-sub">
          <div class="picto-sub">
            <img :src="require('@/assets/pictos/picto_contract_darkpurple.svg')">
          </div>
          <div class="info-sub">
            {{elem.contract}}
          </div>
        </div>
        <div class="elem-sub">
          <div class="picto-sub">
            <img :src="require('@/assets/pictos/picto_sector_darkpurple.svg')">
          </div>
          <div class="info-sub">
            {{elem.sector}}
          </div>
        </div>
        <div class="elem-sub">
          <div class="picto-sub">
            <img :src="require('@/assets/pictos/picto_localisation_darkpurple.svg')">
          </div>
          <div class="info-sub">
            {{elem.location}}
          </div>
        </div>
        <div class="elem-sub">
          <div class="picto-sub">
            <img :src="require('@/assets/pictos/picto_remote_darkpurple.svg')">
          </div>
          <div class="info-sub" v-if="elem.remote === 'No'">
            No remote
          </div>
          <div class="info-sub" v-if="elem.remote === 'Yes'">
            Remote
          </div>
          <div class="info-sub" v-if="elem.remote === 'Partial'">
            Partial remote ({{elem.remoteFrequency}})
          </div>
        </div>
        <div class="elem-sub">
          <div class="picto-sub">
            <img :src="require('@/assets/pictos/picto_euro_darkpurple.svg')">
          </div>
          <div class="info-sub">
            {{elem.salary.amount}}{{elem.salary.currency}} / {{elem.salary.frequency}}
          </div>
        </div>
        <div class="elem-sub">
          <div class="picto-sub">
            <img :src="require('@/assets/pictos/picto_process_darkpurple.svg')">
          </div>
          <div class="info-sub">
            {{elem.stepNumber}} steps
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'JobElemList',
  props: {
    elem: Object,
    redirection: Boolean,
  },

  data() {
    return {
      isRedirect: true,
      companyInfo: {},
    };
  },
  computed: {
  },
  mounted() {
    this.isRedirect = this.redirection;
  },
  methods: {
    getCompanyInfo() {
      const idCompany = this.elem.company._id || this.elem.company;
      this.$api.get(`companyInfo/${idCompany}`).then((res) => {
        this.companyInfo = res.data;
      });
    },
    redirect() {
      if (this.redirection) {
        this.$router.push(`/offer/${this.elem._id}`);
      }
    },
    rightClick() {
      if (this.redirection) {
        window.open(`${window.location.origin}/offer/${this.elem._id}`, '_blank');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
