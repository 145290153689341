<template>
  <div class="component-fullwidth">
    <div class="flex-container flex-full no-padding no-margin">
      <div class="elem-flex">
        <div class="title-page wother">
          {{company.name}}
          <div class="other">
            <social-share :title="`${company.name} on Quasting`" />
            <follow-button :type="'Company'" :accountId="company._id" />
          </div>
        </div>
      </div>
    </div>
    <div class="inner-container cont-profile-talent">
      <div class="flex-container flex-2-tier-sdlarge">
        <div class="elem-flex padRight mhspe flex-overflow-column">
          <div class="selecteur align-left">
            <div
              class="elem-select"
              :class="select_left === 'about me' ? 'selection' : ''"
              @click="select_left = 'about me'"
            >
              About us
            </div>
          </div>
          <div class="container-selected">
            <div class="cont-aboutme" v-if="select_left === 'about me'">
              <div class="flex-container flex-2-tier">
                <div class="elem-flex big-left radius box-shadow">
                  <div class="info-about-me">
                    <div class="about-me-picture"
                      v-if="getWallpaper"
                      :style="`background-image:url(${getWallpaper});`"
                    >
                      <div class="company-logo"
                        :style="
                        `background-image:url(${company.companyPicture});`"
                      >
                      </div>
                    </div>
                    <div class="about-me-picture bis"
                      v-else
                      :style="
                        `background-image:url(${require('@/assets/temp/default-company.png')});`
                      "
                    >
                      <div class="company-logo"
                        :style="
                        `background-image:url(${company.companyPicture});`"
                      >
                      </div>
                    </div>
                    <div class="about-me-user">
                      <div class="elem-me wanted">
                        <span>Work on</span>
                        <p v-if="company.activitySector">{{company.activitySector}}</p>
                        <p v-else>No Information</p>
                      </div>
                      <div class="elem-me localisation">
                        <span>Work in</span>
                        <p v-if="company.localisation">{{company.localisation}}</p>
                        <p v-else>No Information</p>
                      </div>
                      <div class="elem-me desc" >
                        <span>Our Website</span>
                        <a target="_blank"
                          v-if="company.webSite"
                          :href="`${company.webSite}`"
                        >
                          {{company.webSite}}
                        </a>
                        <a
                          v-else
                        >
                          No Information
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="elem-flex relative">
                  <div class="subtitle-talent violet">
                    Social
                  </div>
                  <div id="dash-social-splide"
                    v-if="getOnlyCompleteRS.length > 0"
                    class="radius"
                  >
                    <splide class="list-splide-social"
                      v-if="getOnlyCompleteRS.length <= 1"
                      :options="optionsSocialSolo"
                    >
                      <splide-slide
                        class="elem-splide-social"
                        v-for="event in getOnlyCompleteRS"
                        :key="event._id"
                        @click="redirect(event.link)"
                      >
                        <img
                          v-if="event.site === 'Facebook'"
                          :src="require('@/assets/pictosocial/social-facebook-violet.svg')"
                        >
                        <img
                          v-else-if="event.site === 'Instagram'"
                          :src="require('@/assets/pictosocial/social-instagram-violet.svg')"
                        >
                        <img
                          v-else-if="event.site === 'Twitter'"
                          :src="require('@/assets/pictosocial/social-twitter-violet.svg')"
                        >
                        <img
                          v-else-if="event.site === 'Linkedin'"
                          :src="require('@/assets/pictosocial/social-linkedin-violet.svg')"
                        >
                        <img
                          v-else-if="event.site === 'Github'"
                          :src="require('@/assets/pictosocial/social-github-violet.svg')"
                        >
                        <img
                          v-else-if="event.site === 'Gitlab'"
                          :src="require('@/assets/pictosocial/social-gitlab-violet.svg')"
                        >
                        <img
                          v-else-if="event.site === 'Website'"
                          :src="require('@/assets/pictosocial/social-web-violet.svg')"
                        >
                      </splide-slide>
                    </splide>
                    <splide class="list-splide-social"
                      v-else
                      :options="optionsSocialMultiple"
                    >
                      <splide-slide
                        class="elem-splide-social"
                        v-for="event in getOnlyCompleteRS"
                        :key="event._id"
                        @click="redirect(event.link)"
                      >
                        <img
                          v-if="event.site === 'Facebook'"
                          :src="require('@/assets/pictosocial/social-facebook-violet.svg')"
                        >
                        <img
                          v-else-if="event.site === 'Instagram'"
                          :src="require('@/assets/pictosocial/social-instagram-violet.svg')"
                        >
                        <img
                          v-else-if="event.site === 'Twitter'"
                          :src="require('@/assets/pictosocial/social-twitter-violet.svg')"
                        >
                        <img
                          v-else-if="event.site === 'Linkedin'"
                          :src="require('@/assets/pictosocial/social-linkedin-violet.svg')"
                        >
                        <img
                          v-else-if="event.site === 'Github'"
                          :src="require('@/assets/pictosocial/social-github-violet.svg')"
                        >
                        <img
                          v-else-if="event.site === 'Gitlab'"
                          :src="require('@/assets/pictosocial/social-gitlab-violet.svg')"
                        >
                        <img
                          v-else-if="event.site === 'Website'"
                          :src="require('@/assets/pictosocial/social-web-violet.svg')"
                        >
                      </splide-slide>
                    </splide>
                  </div>
                  <div id="dash-social-splide"
                    v-else
                    class="radius"
                  >
                    <splide class="list-splide-social"
                      v-if="getOnlyCompleteRS.length <= 1"
                      :options="optionsSocialSolo"
                    >
                      <splide-slide
                        class="elem-splide-social"
                      >
                        <div style="height:15vh; font-size:1.6vh;display:flex;align-items:center;">
                          No Social
                        </div>
                      </splide-slide>
                    </splide>
                  </div>
                </div>
              </div>
              <div class="flex-container flex-2-tier reverse">
                <div class="elem-flex radius bgdark">
                  <div class="content-number-comp" id="dash-number-splide">
                    <splide class="list-splide-number"
                      :options="optionsNumberMultiple"
                    >
                      <splide-slide
                        class="elem-splide-number"
                        v-if="company.yearFounded"
                      >
                        <div class="title-sup">Established</div>
                        <div class="data-sup">{{company.yearFounded}}</div>
                      </splide-slide>
                      <splide-slide
                        class="elem-splide-number"
                        v-else
                      >
                        <div class="title-sup">Established</div>
                        <div class="data-sup" style="font-size:1.6vh">No Info</div>
                      </splide-slide>
                      <splide-slide
                        class="elem-splide-number"
                        v-if="company.numEmployees"
                      >
                        <div class="title-sup">People</div>
                        <div class="data-sup">{{company.numEmployees}}</div>
                      </splide-slide>
                      <splide-slide
                        class="elem-splide-number"
                        v-else
                      >
                        <div class="title-sup">People</div>
                        <div class="data-sup" style="font-size:1.6vh">No Info</div>
                      </splide-slide>
                      <splide-slide
                        class="elem-splide-number"
                        v-if="company.parity"
                      >
                        <div class="title-sup">Parity</div>
                        <div class="cont-parity">
                          <div class="inner-parity left-parity">
                            <img :src="require('@/assets/pictos/picto_masculin_white.svg')">
                            {{calcMasculin}}
                          </div>
                          <div class="inner-parity right-parity">
                            <img :src="require('@/assets/pictos/picto_feminin_white.svg')">
                            {{calcFeminin}}
                          </div>
                        </div>
                      </splide-slide>
                      <splide-slide
                        class="elem-splide-number"
                        v-else
                      >
                        <div class="title-sup">Parity</div>
                        <div class="data-sup" style="font-size:1.6vh">No Info</div>
                      </splide-slide>
                      <splide-slide
                        class="elem-splide-number"
                        v-if="company.averageAge"
                      >
                        <div class="title-sup">Average Age</div>
                        <div class="data-sup">{{company.averageAge}}</div>
                      </splide-slide>
                      <splide-slide
                        class="elem-splide-number"
                        v-else
                      >
                        <div class="title-sup">Average Age</div>
                        <div class="data-sup" style="font-size:1.6vh">No Info</div>
                      </splide-slide>
                    </splide>
                  </div>
                </div>
                <div class="elem-flex big-right radius bggrey">
                  <div class="subtitle-talent violet">
                    Technologies
                  </div>
                  <!-- {{company.technologies}} -->
                  <div id="dash-techno-splide"
                    v-if="company.technologies.length > 0"
                    class="radius"
                  >
                    <splide class="list-splide-techno"
                      v-if="company.technologies.length <= 7"
                      :options="optionsTechnoSolo"
                    >
                      <splide-slide
                        class="elem-splide-techno"
                        v-for="techno in company.technologies"
                        :key="techno._id"
                      >
                        <div
                          class="label"
                        >
                          <img
                            v-if="getPictoTechno(techno)"
                            :src="require(`@/assets/pictos/${getPictoTechno(techno)}.svg`)"
                          >
                          <img
                            v-else
                            :src="require(`@/assets/pictos/technos/unspecified.svg`)"
                          >
                          <div class="techno-name">
                            {{techno}}
                          </div>
                        </div>
                      </splide-slide>
                    </splide>
                    <splide class="list-splide-techno"
                      v-else
                      :options="optionsTechnoMultiple"
                    >
                      <splide-slide
                        class="elem-splide-techno"
                        v-for="techno in company.technologies"
                        :key="techno._id"
                      >
                        <div
                          class="label"
                        >
                          <img
                            v-if="getPictoTechno(techno)"
                            :src="require(`@/assets/pictos/${getPictoTechno(techno)}.svg`)"
                          >
                          <img
                            v-else
                            :src="require(`@/assets/pictos/technos/unspecified.svg`)"
                          >
                          <div class="techno-name">
                            {{techno}}
                          </div>
                        </div>
                      </splide-slide>
                    </splide>
                  </div>
                  <div class="radius" v-else>
                    <splide class="list-splide-techno"
                      v-if="company.technologies.length <= 7"
                      :options="optionsTechnoSolo"
                    >
                      <splide-slide
                        class="elem-splide-techno"
                      >
                        <div
                          class="label"
                        >
                          <img
                            :src="require(`@/assets/pictos/technos/unspecified.svg`)"
                          >
                          <div class="techno-name">
                            No Techno
                          </div>
                        </div>
                      </splide-slide>
                    </splide>
                  </div>
                </div>
              </div>
            </div>
            <div class="cont-posts">
              <div class="subtitle-page no-margin">
                Last post published
              </div>
              <div class="dash-feed-splide companyvie"
                v-if="posts.length > 0"
              >
                <splide class="list-splide-feed dash-splide"
                  v-if="posts.length <= 2"
                  :options="optionsFeedSolo"
                >
                  <splide-slide
                    class="elem-splide-feed box-shadow radius-small"
                    v-for="postFeed in posts"
                    :key="postFeed._id"
                  >
                    <post-view :post="postFeed" />
                  </splide-slide>
                </splide>
                <splide class="list-splide-feed dash-splide"
                  v-else
                  :options="optionsFeedMultiple"
                >
                  <splide-slide
                    class="elem-splide-feed box-shadow radius-small"
                    v-for="postFeed in posts.slice(0, 6)"
                    :key="postFeed._id"
                  >
                    <post-view :post="postFeed" />
                  </splide-slide>
                </splide>
              </div>
            </div>
          </div>
        </div>
        <div class="elem-flex mhspe78 flex-overflow-column">
          <div class="selecteur">
            <div
              class="elem-select"
              :class="select_right === 'description' ? 'selection' : ''"
              @click="select_right = 'description'; pslistBGA.update();"
            >
              Description
            </div>
            <div
              class="elem-select"
              :class="select_right === 'job' ? 'selection' : ''"
              @click="select_right = 'job'; pslistBGA.update();"
            >
              Last jobs
            </div>
            <div
              class="elem-select"
              :class="select_right === 'projects' ? 'selection' : ''"
              @click="select_right = 'projects'; pslistBGA.update();"
            >
              Projects
            </div>
          </div>
          <div class="container-selected">
            <div
              id="listBGA"
              class="inner-list list-current-job flex-verti"
              v-if="select_right === 'description'"
            >
              <div class="radius contentbox">
                <p v-html="company.description"
                  v-if="company.description !== 'undefined' &&
                  company.description"></p>
                <p v-else>This company have no description</p>
              </div>
            </div>
            <div
              id="listBGA"
              class="inner-list list-current-job flex-verti"
              v-if="select_right === 'job'"
            >
              <div class="radius contentbox" v-if="company.offers.length >= 1">
                <job-elem-list
                  v-for="offer in offers"
                  :key="offer._id"
                  :elem="offer"
                  :redirection="true"
                />
              </div>
              <div class="radius contentbox no-margin" v-else>
                This company have no job offer
              </div>
            </div>
            <div
              id="listBGA"
              class="inner-list list-current-job list-projects flex-verti"
              v-if="select_right === 'projects'"
            >
              <div class="radius contentbox no-margin" v-if="company.projects.length >= 1">
                <project
                  v-for="project in company.projects"
                  :key="project._id"
                  :project="project"
                  :editable="false"
                />
              </div>
              <div class="radius contentbox no-margin" v-else>
                This company have no project
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Loading from '@/components/utilityPartials/_loading.vue';
// import GlobalNav from '@/components/navigation/GlobalNav.vue';
import PerfectScrollbar from 'perfect-scrollbar';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import JobElemList from '@/components/UniversalComponents/Offer/JobElemList.vue';
import PostView from '@/components/UniversalComponents/Feed/_post.vue';
// import ToggleSwitch from '@/components/utilityPartials/ToggleSwitch.vue';
import SocialShare from '@/components/UniversalComponents/SocialMedia/socialShareList.vue';
import FollowButton from '@/components/UniversalComponents/Feed/following/followButton.vue';
import Project from '@/components/EmployeeComponents/CompanySettings/_project.vue';
import technoList from '@/components/TalentComponents/talentTechnos/talentTechnoList';

export default {
  name: 'CompanyView',
  components: {
    // Loading,
    // GlobalNav,
    JobElemList,
    PostView,
    // ToggleSwitch,
    SocialShare,
    FollowButton,
    Project,
    Splide,
    SplideSlide,
  },
  data() {
    return {
      loading: true,
      company: {},
      offers: [],
      posts: [],
      reverse: true,
      select_left: 'about me',
      select_right: 'description',
      pslistBGA: '',
      optionsFeedSolo: {
        perPage: 3,
        gap: '2vh',
        padding: '1vh',
        rewind: true,
        arrows: false,
        pagination: false,
        drag: false,
      },
      optionsFeedMultiple: {
        perPage: 3,
        perMove: 1,
        gap: '2vh',
        padding: '0.3vh',
        rewind: true,
        drag: false,
      },
      optionsNumberMultiple: {
        perPage: 1,
        rewind: true,
        pagination: false,
        drag: false,
        autoWidth: false,
        autoHeight: false,
        autoplay: true,
      },
      optionsNumberSingle: {
        perPage: 1,
        rewind: false,
        pagination: false,
        arrows: false,
        drag: false,
        autoWidth: false,
        autoHeight: false,
        autoplay: false,
      },
      optionsSocialSolo: {
        perPage: 1,
        rewind: true,
        pagination: false,
        arrows: false,
        drag: false,
        autoWidth: false,
        autoHeight: false,
        autoplay: true,
      },
      optionsSocialMultiple: {
        perPage: 1,
        rewind: true,
        pagination: false,
        drag: false,
        autoWidth: false,
        autoHeight: true,
        autoplay: true,
      },
      optionsTechnoSolo: {
        perPage: 7,
        perMove: 1,
        rewind: true,
        pagination: false,
        arrows: false,
        drag: false,
        autoplay: true,
        gap: '1vh',
      },
      optionsTechnoMultiple: {
        perPage: 7,
        perMove: 1,
        rewind: true,
        pagination: false,
        drag: false,
        autoplay: true,
        gap: '1vh',
      },
    };
  },
  computed: {
    calcMasculin() {
      return `${this.company.parity * 100}%`;
    },
    calcFeminin() {
      return `${100 - (this.company.parity * 100)}%`;
    },
    getOnlyCompleteRS() {
      return this.company.socials.filter((u) => u.link !== undefined);
    },
    getWallpaper() {
      return this.company.companyBackgroundPicture;
    },
    projects() {
      return this.$store.getters['company/projects'];
    },
  },
  mounted() {
    this.$api.get(`companyInfo/${this.$route.params.id}`).then((res) => {
      this.loading = false;
      this.company = res.data;
      this.offers = res.data.offers;
      this.getPost();
    });
    setTimeout(() => {
      this.pslistBGA = new PerfectScrollbar('#listBGA', {
        wheelPropagation: false,
        suppressScrollX: true,
      });
      this.pslistBGA.update();
    }, 1500);
  },
  methods: {
    getPost() {
      this.$api.get(`feed/posts/Company/${this.company._id}`).then((res) => {
        this.posts = res.data;
      });
    },
    handleClick() {
      this.reverse = !this.reverse;
    },
    getLinkRight(link) {
      if (link.includes('https://') || link.includes('http://')) {
        return link;
      }
      return `https://${link}`;
    },
    switchTab(value) {
      window.open(`${window.location.origin}/${value}`, '_blank');
    },
    switchNewTab(value) {
      window.open(`${window.location.origin}/${value}`, '_blank');
    },
    getPictoTechno(tech) {
      if (this.findByMatchingProperties(technoList, { text: tech }).length > 0) {
        return this.findByMatchingProperties(technoList, { text: tech })[0].picto;
      }
      return false;
    },
    findByMatchingProperties(set, properties) {
      return set.filter((entry) => Object.keys(properties).every(
        (key) => entry[key] === properties[key],
      ));
    },
  },

};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
  @import "@/assets/scss/_splide.scss";
  @import "@/assets/scss/_variables.scss";

  .title-page{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: $logo_color;
  }

  .cont-profile-talent{
    .padRight{
      padding-right: 0vh;
    }
  }

  .selecteur{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3vh;
    &.align-left{
      justify-content: flex-start;
      .elem-select{
        margin-right: 4vh;
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .elem-select{
      position: relative;
      font-size: 1.8vh;
      padding-bottom: 2vh;
      cursor: pointer;
      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        opacity: 0;
        height: 0.5vh;
        border-radius: 0.5vh;
        background-color: $logo_color;
        transition: all .2s;
      }
      &.selection{
        font-family: 'MontserratBold';
        &:after{
          width: 60%;
          opacity: 1;
        }
      }
    }
  }

  #listBGA{
    height: 70vh;
    .subtitle-page{
      display: none;
    }
    .cont_experiences_entry, .cont_academic_entry{
      margin-top: 0;
    }
    .ResumeEntry, .cert-view{
      margin-bottom: 1vh;
      .cont-btn{
        display: none;
      }
      &:first-child{
        margin-bottom: 1vh !important;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
    .ap-view{
      border: none;
      border-radius: 1vh;
    }
  }

  .bgdark{
    background-color: $text_color;
  }

  .bggrey{
    background-color: $fond_light_gray;
  }

  .container-selected{
    width: 100%;
    .flex-container{
      align-items: unset;
      width: 100%;
      margin-bottom: 2vh;
      .elem-flex{
        position: relative;
        overflow: hidden;
        flex: 1 auto;
        &.big-left{
          margin-right: 4vh;
        }
        &.big-right{
          margin-left: 4vh;
        }
        &.relative{
          position: relative;
        }
        .subtitle-talent{
          position: absolute;
          top: 2vh;
          left: 2vh;
          font-family: 'MontserratBold';
          font-size: 1.6vh;
          &.violet{
            color: $logo_color;
          }
          &.green{
            color: $green_color;
          }
        }
      }
    }
  }

  .content-skills-talent{
    display:flex;
    flex-wrap: wrap;
    padding: 2vh;
    margin-top: 3vh;
    width: calc(100% - 4vh);
    .elem-skill-talent{
      text-transform: uppercase;
      font-size: 1.6vh;
      font-family: 'MontserratBold';
      margin-right: 0.5vh;
      color: #ffffff;
      &:nth-child(even){
        color: $logo_color;
      }
    }
  }

  .info-about-me{
    width: 100%;
    height: 100%;
    background-color: $logo_color;
    color: #ffffff;
    display: flex;
    flex:1;
    .about-me-picture{
      position: relative;
      flex: 0.60;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-color: #e8e8e8;
      .company-logo{
        position: absolute;
        bottom: 1vh;
        right: 1vh;
        height: 8vh;
        border: 0.3vh solid $green_color;
        background-color: $text_color;
        width: 8vh;
        background-size: cover;
        border-radius: 50%;
      }
    }
    .about-me-user{
      flex: 0.4;
      padding: 2vh;
      font-size: 1.2vh;
      font-family: 'MontserratBold';
      display: flex;
      flex-direction: column;
      justify-content: center;
      span{
        display: block;
        font-family: 'MontserratRegular';
        margin-bottom: 0.5vh;
        line-height: 1;
      }
      .elem-me{
        margin-bottom: 1.5vh;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  .cont-posts{
    margin-top: 2vh;
    .splide__track{
      padding: 0.5vh 0vh 0.5vh 0.5vh;
    }
    .elem-feed{
      // max-height: 29vh;
      .top-feed{
        width: calc(100% - 1vh);
        max-height: 19vh;
        padding: 0 0.5vh 0.5vh 0.5vh;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .visual-feed{
          padding: 0;
          width: 100%;
          max-height: 18vh;
          height: 100%;
          img{
            width: 100%;
            height: auto;
          }
          video{
            width: 100%;
            height: 100%;
          }
        }
        .head-feed{
          // max-height: 25vh;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
      }
      .footer-feed{
        .elem-footer{
          .pic-name{
            display: none !important;
          }
          .elem-lightbulb{
            align-self: flex-end;
            justify-self: flex-end;
            position: relative;
            top: unset;
            right: unset;
            transform: unset;
            display:flex;
            align-items: center;
            flex-wrap: wrap;
            .label{
              margin-left: 0.5vh
            }
          }
        }
      }
    }
  }

  .list-current-job{
    width: calc(100% - 1vh);
      padding: 0.5vh;
    .elem-job{
      margin-bottom: 2vh;
    }
  }
</style>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  #listsroll{
    margin-top: 3vh;
  }
  .cont-list, .inner-feed-entry{
    margin-top: 2vh;
    .elem-feed{
      width: calc(calc(100% - 2vh) / 2);
      margin-right: 2vh;
      &:nth-child(2n){
        margin-right: 0;
      }
      &:last-child{
        width: 100%;
        margin-right: 0;
      }
    }
  }
</style>

<style lang="scss">
.companyvie {
  width: 100%;
  display: block;
  .list-splide-feed {
    padding: 0.5vh;
    display: block;
    .elem-splide-feed {
      position: relative;
      background-color: #ffffff;
      padding: 1vh;
      max-height: 31vh !important;
      overflow: hidden;
      .elem-feed {
        // display: block;
      }
    }
    &.splidepadding {
      .splide__track {
        padding: 1vh;
      }
    }
  }
}
</style>
